.rta__autocomplete {
    position: absolute;
    display: block;
    margin-top: 1em;
}

.rta {
    position: relative;
    font-size: 18px;
    width: 100%;
    height: 100%;
}

.rta__list {
    margin: 0;
    margin-top:20px;
    padding: 0;
    background: #fff;
    border: 1px solid #dfe2e5;
    border-radius: 3px;
    box-shadow: 0 0 5px rgba(27, 31, 35, 0.1);
    list-style: none;
}

li {
    display: list-item;
    text-align: match-parent;
}

.rta__item:not(:last-child) {
    border-bottom: 1px solid #dfe2e5;
}

.rta__entity:hover {
    cursor: pointer;
}

.rta__entity--selected {
    color: #fff;
    text-decoration: none;
    background: #0366d6;
}

.rta .MuiFormControl-root{
    width:100%
}

.rta__list {
    position: fixed;
    max-height: 250px;
    min-height: 100px;
    overflow: auto;
    z-index: 10;
}