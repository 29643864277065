body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body > #root {
  height: 100%;
}

.content-wrapper {
  height: calc(100vh - 57px) !important;
}

.box-overflow {
  position: relative;
  overflow: auto;
  width: 100%;
}

.btn {
  padding: 0.15rem 0.75rem !important;
  font-size: 1.2rem !important;
}

.invalid-feedback {
  color: rgba(250, 65, 65, 1) !important;
  display: inline !important;
  margin: 0.25rem !important;
}

textarea{
  width:100%;
  padding:10px;
}

.textarea {
  overflow: auto !important;
  resize: both !important;
}

.content {
  padding-top: 15px !important;
  background-color: #f4f6f9;
}
